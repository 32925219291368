import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";

export const OrdersPieChartActive = ({ goal }) => {
    const percentage =
        Math.round(
            (Number(goal?.completed_threats_count) * 100) /
                Number(goal?.threats_count)
        ) || 0;

    const option = {
        color: [
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                    offset: 0,
                    color: "#F3A773",
                },
                {
                    offset: 1,
                    color: "#F3A773",
                },
            ]),
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                    offset: 0,
                    color: "#F3A77380",
                },
                {
                    offset: 1,
                    color: "#F3A77380",
                },
            ]),
        ],
        series: [
            {
                name: "Item",
                type: "pie",
                radius: ["80%", "100%"],
                avoidLabelOverlap: false,
                label: {
                    position: "center",
                    fontSize: 32,
                    fontWeight: 700,
                    color: "#264354CC",
                    formatter: `${percentage}%`,
                },
                emphasis: {
                    scale: false,
                },
                data: [
                    {
                        value: percentage,
                        name: `${percentage}%`,
                    },
                    { value: 100 - percentage, name: "" },
                ],
            },
        ],
    };

    return (
        <ReactECharts
            style={{ height: "100%", width: "100%" }}
            option={option}
        />
    );
};
