import LogInForm from "shared/ui/forms/LoginForm";
import HumanLogin from "shared/image/greetingPic3.png";

function Authorization() {
    return (
        <section className="relative flex h-screen w-full items-center justify-center overflow-hidden">
            <div className="flex h-full w-full max-w-[1415px] justify-between px-[40px] pt-[30px] max-[1124px]:justify-center max-[1124px]:pt-[25px]">
                <LogInForm />
                <img
                    src={HumanLogin}
                    alt="greeting"
                    className="absolute top-0 right-0 h-full max-[1124px]:hidden"
                />
            </div>
        </section>
    );
}

export default Authorization;
