import { useNavigate } from "react-router-dom";
import { OrdersPieChartGoal } from "components/OrdersPieChart/OrdersPieChartGoal";
import useMaterialsGoal from "shared/api/useMaterialsGoal";

export const CardGoalMain = ({ active }) => {
    const navigate = useNavigate();
    const materialGoal = useMaterialsGoal(active.id);
    const completedMaterialCount = materialGoal.data?.results.filter(
        (item) => item.session.completion_status === "completed"
    ).length;

    const handleLinkGoal = (id) => {
        navigate(`timeline/${id}`, { state: { active } });
    };

    const hasMaterials = !!materialGoal?.data?.results?.length;

    if (materialGoal.isLoading) {
        return (
            <div className="flex h-[300.4px] min-w-[695px] justify-between rounded-[10px] p-[20px] shadow-[0px_0px_15px_0px_#2643541A]">
                <div className="flex h-full flex-col justify-between">
                    <div className="skeleton-animated h-[27px] w-[130px] rounded-[5px]" />
                    <div className="skeleton-animated mt-2 h-[27px] w-[220px] rounded-[5px]" />
                    <div className="invisible grow" />
                    <div className="skeleton-animated h-[27px] w-[300px] rounded-[5px]" />
                    <div className="skeleton-animated mt-2 h-[55px] w-[75px] rounded-[5px]" />
                </div>
                <div className="flex h-full flex-col items-end justify-between">
                    <div className="skeleton-animated mt-[17.5px] h-[100px] w-[100px] rounded-[5px]" />
                    <div className="skeleton-animated h-[35px] w-[172px] rounded-[30px]" />
                </div>
            </div>
        );
    }

    return (
        <div
            data-hasMaterials={hasMaterials}
            className="flex min-w-[695px] flex-col justify-between rounded-[10px] p-[20px] shadow-[0px_0px_15px_0px_#2643541A] data-[hasMaterials=false]:grayscale"
        >
            <div className="flex justify-between">
                <div className="mb-[89px] flex flex-col gap-[10px]">
                    <p className="text-[14px] font-medium leading-[16.8px] text-[#F3A773]">
                        Цель
                    </p>
                    <p className="text-[16px] font-medium leading-[19.2px] text-[#264354]">
                        {active?.active?.name}
                    </p>
                </div>
                <div className="max-w-[101px]">
                    <OrdersPieChartGoal data={active} />
                </div>
            </div>
            <div className="flex flex-col gap-[21px]">
                <p
                    data-hasMaterials={hasMaterials}
                    className="mt-[30px] text-[14px] font-medium leading-[16.8px] text-[#264354B2] data-[hasMaterials=false]:invisible"
                >
                    Освоенных материалов в рамках цели
                </p>
                <div className="flex items-end justify-between">
                    {hasMaterials ? (
                        <p className="text-[48px] font-medium leading-[57.6px] text-[#566DA3] max-[1010px]:text-[32px] max-[1010px]:leading-[38.4px]">
                            {completedMaterialCount}
                        </p>
                    ) : (
                        <p className="max-w-[395px] text-tr-s text-main-dark/70">
                            Эта цель формируется и будет доступна к достижению
                            спустя некоторое время
                        </p>
                    )}

                    <button
                        disabled={!hasMaterials}
                        className="flex h-[35px] items-center rounded-full bg-[#73C3F3CC] px-[18px] py-[5px]"
                        onClick={() => handleLinkGoal(active.id)}
                    >
                        <p className="text-[14px] font-medium text-[#FFFFFF] ">
                            {Math.round(
                                (Number(active.completed_threats_count) * 100) /
                                    Number(active.threats_count)
                            ) === 0
                                ? "Начать обучение"
                                : "Продолжить обучение"}{" "}
                            &#8594;
                        </p>
                    </button>
                </div>
            </div>
        </div>
    );
};
