import { FC, CSSProperties } from "react";
import "./ProgressBar.css";

interface Props {
    percent: number;
    background: string;
    color: string;
    height: string;
    fontSize: string;
    lineHeight: string;
    top: string;
    right: string;
}

export const ProgressBar: FC<Props> = ({
    percent,
    background,
    color,
    height,
    fontSize,
    lineHeight,
    top,
    right,
}) => {
    const fillerStyles: CSSProperties = {
        width: `${percent}%`,
        background: background,
        position: "relative",
    };

    const heightStyles: CSSProperties = {
        height,
    };

    const textStyle: CSSProperties = {
        color: color,
        fontSize: fontSize,
        lineHeight: lineHeight,
        top,
        right,
    };

    return (
        <>
            <div className="progress-bar" style={heightStyles}>
                <div className="filler" style={fillerStyles}>
                    <p className="text-color" style={textStyle}>
                        {percent}%
                    </p>
                </div>
            </div>
        </>
    );
};
