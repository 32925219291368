import { ReactComponent as Training } from "shared/image/icon/Training.svg";
import { useNavigate } from "react-router-dom";

export const NoMaterialLearning = () => {
    const navigate = useNavigate();
    return (
        <div className="flex justify-between">
            <div className="flex items-center gap-[20px]">
                <p className="text-[24px] font-medium leading-[28.8px] text-[#264354] opacity-[80%]">
                    Кажется, что вы еще не начали обучение
                </p>
                <button
                    className="flex h-[35px] items-center rounded-full bg-[#73C3F3CC] px-[18px] py-[5px]"
                    onClick={() => navigate("/materials")}
                >
                    <p className="text-[14px] font-medium text-[#FFFFFF] ">
                        Начать изучать &#8594;
                    </p>
                </button>
            </div>
            <Training />
        </div>
    );
};
