import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useSkillsGoal from "shared/api/useSkillsGoal";
import { Loader } from "components/Loader/Loader";
import { useStore } from "effector-react";
import {
    CreateModelApi,
    CreatePaginatedModel,
} from "shared/paginatedModelBuilder";
import * as types from "shared/types";
import { ReactComponent as SkillIconGreen } from "shared/image/icon/SkillIconGreen.svg";
import { ReactComponent as Left } from "shared/ui/icon/Left.svg";
import { ReactComponent as Right } from "shared/ui/icon/Right.svg";
import { CardMaterialTimeline } from "components/CardMaterialTimeline/CardMaterialTimeline";
import { OrdersPieChartActive } from "components/OrdersPieChart/OrdersPieChartActive";
import { CardThreatTimeline } from "components/CardThreatTimeline/CardThreatTimeline";
import useMaterialsGoal from "shared/api/useMaterialsGoal";
import useGoal from "shared/api/useGoal";
import { MaterialTimeline } from "components/MaterialItem/MaterialTimeline";
import { truncateText } from "utils/truncateText";

const ContentModel = CreatePaginatedModel<types.MaterialType>("Content");
const ActivesModel =
    CreatePaginatedModel<types.TrajectoryActiveType>("Actives");

export const Timeline: React.FC = () => {
    const [openList, setOpenList] = useState(false);
    const [skillId, setSkillId] = useState(null);
    const location = useLocation();
    const goal = location.state.active;
    const { data, isLoading } = useSkillsGoal(goal.id);
    const { dataGoal } = useGoal(goal?.id);
    const [skillName, setSkillName] = useState(data?.results[0]?.threat?.name);
    const navigate = useNavigate();
    const [allMaterials, setAllMaterials] = useState(true);
    const materials = useStore(ContentModel.store);
    const [width, setWidth] = useState(window.innerWidth);
    const materialGoal = useMaterialsGoal(goal.id);
    const completedMaterialCount = materialGoal.data?.results.filter(
        (item) => item.session.completion_status === "completed"
    ).length;
    const [selectedThreat, setSelectedThreat] = useState<types.ActiveThreatType | undefined>();

    const handleClickLinkGoal = (evt) => {
        evt.stopPropagation();
        navigate("/goals");
    };

    const handleClickLinkMain = (evt) => {
        evt.stopPropagation();
        navigate("/");
    };

    const handleClickLinkBack = (evt) => {
        evt.stopPropagation();
        navigate(-1);
    };

    const ModelApi = CreateModelApi(
        ContentModel,
        `/api/v1/user/active_threat/${skillId}/content`,
        ""
    );

    const ModelApiGoal = CreateModelApi(
        ActivesModel,
        `/api/v1/trajectory_active/${goal?.id}`,
        ""
    );

    const scrollLeft = () => {
        const ul = document.querySelector(".container-scroll");
        ul.scrollBy(-250, 0);
    };

    const scrollRight = () => {
        const ul = document.querySelector(".container-scroll");
        ul.scrollBy(250, 0);
    };

    useEffect(() => {
        if (skillId) {
            ModelApi.loadFirstPage().then(() => console.log());
        }
        ModelApiGoal.loadFirstPage().then(() => console.log());
    }, [skillId]);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
    }, [width]);

    useEffect(() => {
        if (data?.results?.length > 0) {
            setSkillName(data?.results[0]?.threat?.name);
        }
    }, [data]);

    materials?.results?.map((item) => console.log(item));
    console.log(materialGoal);
    if (isLoading) return <Loader text="Загрузка..." />;

    return (
        <main className="flex w-full flex-col overflow-auto">
            <section className="relative flex w-full justify-center">
                <div className="max-[834px]:mb-[60px] mb-[10px] mt-[30px] flex w-full max-w-[1415px] justify-between px-[16px]">
                    <div className="flex w-full flex-col">
                        <div className="mb-[45px] flex flex-col gap-[19px] max-[834px]:mb-[40px]">
                            <p className="cursor-pointer text-[14px] font-normal leading-[16.8px] text-[#2643544D]">
                                <span onClick={handleClickLinkBack}>
                                    &#8592;
                                </span>{" "}
                                <span onClick={handleClickLinkMain}>
                                    Главная
                                </span>{" "}
                                <span onClick={handleClickLinkGoal}>/Цели</span>
                            </p>
                            <h2 className="text-[20px] font-bold leading-[24px] text-[#264354]">
                                {goal?.active?.name}
                            </h2>
                        </div>
                        <p className="mb-[10px] text-[20px] font-medium leading-[24px] text-[#264354] opacity-[80%] max-[834px]:text-[16px] max-[834px]:leading-[19.2px]">
                            Прогресс по достижению цели
                        </p>
                        <div className="flex items-end justify-between">
                            <div className="mr-[10px] flex w-full max-w-[493px] items-end gap-[40px]">
                                <div className="max-[834px]:h-[169px] max-[834px]:max-w-[169px] h-[214px] w-[100%] max-w-[214px]">
                                    <OrdersPieChartActive goal={dataGoal} />
                                </div>
                                <div className="flex flex-col gap-[29px]">
                                    <p className="text-[16px] font-medium leading-[19.2px] text-[#264354] opacity-[80%]">
                                        Изучено учебных материалов
                                    </p>
                                    <div className="flex items-end gap-[12px]">
                                        <p className="max-[1010px]:text-[24px] max-[1010px]:leading-[28.8px] text-[32px] font-medium leading-[38.4px] text-[#566DA3]">
                                            {completedMaterialCount}/
                                            {materialGoal.data?.results?.length}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <CardMaterialMain /> */}
                        </div>
                        <div className="mt-[50px] hidden flex-col gap-[25px] pb-[20px] max-[834px]:flex">
                            <p className="mb-[21px] flex text-[20px] font-medium leading-[24px] text-[#264354] opacity-[80%] max-[834px]:text-[16px] max-[834px]:leading-[19.2px]">
                                Учебных материалов в текущей цели
                            </p>
                            {data?.results?.length !== 0 ? (
                                <ul className="hidden h-full w-full flex-col items-center space-y-[30px] overflow-y-scroll pb-[20px] max-[834px]:flex">
                                    {materialGoal.data?.results?.map((item) => (
                                        <li
                                            className="mt-[20px] w-full max-w-[1415px] px-[16px]"
                                            key={item?.id}
                                            children={
                                                <MaterialTimeline item={item} />
                                            }
                                        />
                                    ))}
                                </ul>
                            ) : (
                                <div className="text-center">Данных нет</div>
                            )}
                        </div>
                        {/* <div className="flex max-h-[311px] w-full max-w-[215px] flex-col gap-[30px] overflow-y-auto">
                            <div className="flex items-center gap-[10px]">
                                <SkillIconGreen className="h-[30px] w-[16px]" />
                                <p className="text-[20px] font-medium leading-[24px] text-[#264354]">
                                    Навыки в цели
                                </p>
                            </div>
                            <div className="flex flex-col gap-[20px]">
                                <div
                                    className={`flex h-[54px] cursor-pointer ${
                                        allMaterials
                                            ? "bg-[#369F481A]"
                                            : "bg-white"
                                    }
                                    items-center justify-between rounded-md bg-[#369F481A] p-[10px] hover:shadow-[0_0_25px_0_rgba(38,67,84,0.1)]`}
                                    onClick={() => {
                                        setAllMaterials(true);
                                    }}
                                >
                                    <p className="text-[14px] font-medium leading-[16.8px] text-[#264354]">
                                        Все учебные материалы
                                    </p>
                                    <p className="rounded-full bg-[#566DA3] px-[10px] py-[5px] text-[14px] font-medium leading-[16.8px] text-white">
                                        {materialGoal.data?.results
                                            ?.length > 100
                                            ? "100+"
                                            : materialGoal.data?.results
                                                    ?.length}
                                    </p>
                                </div>
                                {data?.results?.length >= 0 ? (
                                    <div className="flex max-h-[500px] flex-col gap-[20px] pb-[10px]">
                                        {data?.results?.map(
                                            (item) =>
                                                item?.content_count > 0 && (
                                                    <CardThreatTimeline
                                                        item={item}
                                                        skillId={skillId}
                                                        setSkillId={
                                                            setSkillId
                                                        }
                                                        setSkillName={
                                                            setSkillName
                                                        }
                                                        setAllMaterials={
                                                            setAllMaterials
                                                        }
                                                        allMaterials={
                                                            allMaterials
                                                        }
                                                    />
                                                )
                                        )}
                                    </div>
                                ) : (
                                    <div className="text-center">
                                        Данных нет
                                    </div>
                                )}
                            </div>
                        </div> */}
                        <div className="relative flex flex-col mt-[20px]">
                            {openList === false ? (
                                <div
                                    onClick={() => setOpenList(true)}
                                    className="flex w-full max-w-[600px] h-[85px] rounded-full shadow-[0_0_10px_0_rgba(38,67,84,0.1)] items-center justify-between px-[30px] cursor-pointer"
                                >
                                    {selectedThreat === undefined ? (
                                        <>
                                            <p className="text-[20px] font-medium leading-[24px] text-[#264354CC]">
                                                Все учебные материалы 
                                            </p> 
                                            <div className="flex gap-x-[25px] items-center">
                                                <p className="min-w-[50px] text-center rounded-full h-[25px] bg-[#566DA3] py-[5px] text-[14px] font-medium leading-[16.8px] text-white">
                                                    {materialGoal.data?.results
                                                        ?.length > 100
                                                        ? "100+"
                                                        : materialGoal.data?.results
                                                                ?.length}
                                                </p> 
                                                <div className="flex text-[#F37D73] text-[20px]">
                                                    &#9660;
                                                </div>
                                            </div>   
                                        </>
                                    ):(
                                        <> 
                                            <div className="flex flex-col gap-y-[5px]">
                                                <p className="font-medium text-[14px] leading-[16.8px] text-[#2643544D]">
                                                    Навык
                                                </p>
                                                <p className="text-[20px] font-medium leading-[24px] text-[#264354CC]">
                                                    {truncateText(selectedThreat.threat.name, 1.5)}
                                                </p>
                                            </div>
                                            <div className="flex gap-x-[25px] items-center">
                                                <p className="min-w-[50px] text-center rounded-full h-[25px] bg-[#566DA3] py-[5px] text-[14px] font-medium leading-[16.8px] text-white">
                                                    {selectedThreat?.content_count > 100 ? "100+" : selectedThreat?.content_count}
                                                </p> 
                                                <div className="flex text-[#F37D73] text-[20px]">
                                                    &#9660;
                                                </div>
                                            </div>   
                                        </>
                                    )}
                                </div>
                            ):(
                                <div className="z-50 bg-white absolute flex flex-col w-full max-w-[600px] max-h-[415px] rounded-2xl shadow-[0_0_10px_0_rgba(38,67,84,0.1)] cursor-pointer">
                                    {selectedThreat === undefined ? (
                                        <div
                                            onClick={() => (setAllMaterials(true), setOpenList(false))}
                                            className="flex min-h-[85px] w-full max-w-[600px]  items-center justify-between px-[30px]"
                                        >
                                            <p className="text-[20px] font-medium leading-[24px] text-[#264354CC] ">
                                                Все учебные материалы
                                            </p> 
                                            <div className="flex gap-x-[25px] items-center">
                                                <p className="min-w-[50px] text-center  rounded-full h-[25px] bg-[#566DA3] py-[5px] text-[14px] font-medium leading-[16.8px] text-white">
                                                    {materialGoal.data?.results
                                                        ?.length > 100
                                                        ? "100+"
                                                        : materialGoal.data?.results
                                                                ?.length}
                                                </p> 
                                                <div className="flex text-[#F37D73] text-[20px]">
                                                    &#9650;
                                                </div>
                                            </div>
                                        </div>
                                    ):(
                                        <div
                                            onClick={() => (setOpenList(false))}
                                            className="flex min-h-[85px] w-full max-w-[600px]  items-center justify-between px-[30px]"
                                        >
                                            <div className="flex flex-col gap-y-[5px]">
                                                <p className="font-medium text-[14px] leading-[16.8px] text-[#2643544D]">
                                                    Навык
                                                </p>
                                                <p className="text-[20px] font-medium leading-[24px] text-[rgba(38,67,84,0.8)]">
                                                    {truncateText(selectedThreat.threat.name, 1.5)}
                                                </p>
                                            </div>
                                            <div className="flex gap-x-[25px] items-center">
                                                <p className="min-w-[50px] text-center rounded-full h-[25px] bg-[#566DA3] py-[5px] text-[14px] font-medium leading-[16.8px] text-white">
                                                    {selectedThreat?.content_count > 100 ? "100+" : selectedThreat?.content_count}
                                                </p> 
                                                <div className="flex text-[#F37D73] text-[20px]">
                                                    &#9660;
                                                </div>
                                            </div>   
                                        </div>
                                    )}
                                    {data?.results?.length >= 0 ? (
                                        <div className="flex flex-col pb-[10px] overflow-y-auto">
                                            {selectedThreat !== undefined && (
                                                <div
                                                    className={`flex w-full h-[80px] border-b-[1px] border-b-[#264354CC] cursor-pointer items-center justify-between p-[30px] hover:shadow-[0_0_25px_0_rgba(38,67,84,0.1)]`}
                                                    onClick={() => {
                                                        setAllMaterials(true);
                                                        setOpenList(false);
                                                        setSelectedThreat(undefined);
                                                    }}
                                                >
                                                    <p className="text-[20px] font-medium leading-[24px] text-[#264354CC] ">
                                                        Все учебные материалы
                                                    </p> 
                                                    <div className="flex gap-x-[25px] items-center">
                                                        <p className="min-w-[50px] text-center  rounded-full h-[25px] bg-[#566DA3] py-[5px] text-[14px] font-medium leading-[16.8px] text-white">
                                                            {materialGoal.data?.results
                                                                ?.length > 100
                                                                ? "100+"
                                                                : materialGoal.data?.results
                                                                        ?.length}
                                                        </p> 
                                                        <div className="flex text-[#F37D73] text-[20px]">
                                                            &#9650;
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {data?.results?.map(
                                                (item) =>
                                                    item?.content_count > 0 && (
                                                        <CardThreatTimeline
                                                            item={item}
                                                            skillId={skillId}
                                                            setSkillId={
                                                                setSkillId
                                                            }
                                                            setSkillName={
                                                                setSkillName
                                                            }
                                                            setAllMaterials={
                                                                setAllMaterials
                                                            }
                                                            allMaterials={
                                                                allMaterials
                                                            }
                                                            setOpenList={setOpenList}
                                                            setSelectedThreat={setSelectedThreat}
                                                        />
                                                    )
                                            )}
                                        </div>
                                    ) : (
                                        <div className="text-center">
                                            Данных нет
                                        </div>
                                    )} 
                                </div>
                            )}

                            <div className={`${!openList ? "mt-[20px]" : "mt-[105px]"} flex gap-[25px] pb-[20px] max-[834px]:hidden`}>
                                <div className="flex w-full flex-col justify-between overflow-auto max-[834px]:hidden">
                                    {!allMaterials ? (
                                        materials?.results?.length > 0 ? (
                                            <>
                                                <ul
                                                    className="container-scroll flex py-[10px] pr-[10px]"
                                                    style={{ overflowX: "hidden" }}
                                                >
                                                    {materials?.results?.map(
                                                        (item) => (
                                                            <CardMaterialTimeline
                                                                item={item}
                                                                skillName={
                                                                    skillName
                                                                }
                                                                allMaterials={
                                                                    allMaterials
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </ul>
                                                <div className="mt-[30px] flex cursor-pointer justify-center gap-x-[45px]">
                                                    <Left onClick={scrollLeft} />
                                                    <Right onClick={scrollRight} />
                                                </div>
                                            </>
                                        ) : (
                                            <div className="flex h-full w-full items-center justify-center">
                                                Данных нет
                                            </div>
                                        )
                                    ) : materialGoal.data?.results?.length > 0 ? (
                                        <>
                                            <ul
                                                className="container-scroll flex py-[10px] pr-[10px]"
                                                style={{ overflowX: "hidden" }}
                                            >
                                                {materialGoal.data?.results?.map(
                                                    (item) => (
                                                        <CardMaterialTimeline
                                                            item={item}
                                                            skillName={skillName}
                                                            allMaterials={
                                                                allMaterials
                                                            }
                                                        />
                                                    )
                                                )}
                                            </ul>
                                            <div className="mt-[30px] flex cursor-pointer justify-center gap-x-[45px]">
                                                <Left onClick={scrollLeft} />
                                                <Right onClick={scrollRight} />
                                            </div>
                                        </>
                                    ) : (
                                        <div className="flex h-full w-full items-center justify-center">
                                            Данных нет
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Timeline;
