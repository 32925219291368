import { useNavigate } from "react-router-dom";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { MaterialType } from "shared/types";
import { truncateText } from "../../utils/truncateText";
import { formatTime } from "utils/formatTime";

interface Props {
    item: MaterialType;
}

export const MaterialTimeline: React.FC<Props> = ({ item }) => {
    const navigate = useNavigate();

    const handleClickLink = () => {
        navigate(`/session/${item.id}`);
    };

    return (
        <div
            className="group relative flex h-[358px] w-full cursor-pointer flex-col rounded-xl bg-white text-[16px] font-bold text-textblack shadow-[0_0_10px_0_rgba(38,67,84,0.1)]"
            onClick={handleClickLink}
        >
            <div className="mb-[41px] flex justify-between px-[20px] pt-[20px]">
                <div className="flex flex-col gap-[10px]">
                    <p className="text-[12px] font-medium leading-[14.4px] text-[#566DA3]">
                        {item?.content_type?.verbose_name}
                    </p>
                    <p className="text-[14px] font-semibold leading-[16.8px] text-textblack">
                        {truncateText(item?.name, 1.4)}
                    </p>
                </div>
                <div className="flex flex-col gap-[10px]">
                    <p className="text-[12px] font-semibold leading-[14.4px] text-[#F37D73]">
                        Длительность
                    </p>
                    <p className="text-[14px] font-semibold leading-[16.8px] text-[#F37D73]">
                        {formatTime(item?.content_duration)}
                    </p>
                </div>
            </div>
            <div className="relative flex h-full w-full flex-col overflow-hidden rounded-l-md px-[20px]">
                <div className="mb-[40px] flex w-full flex-col">
                    <p className="mb-[25px] text-[12px] font-medium leading-[14.4px] text-[#264354B2]">
                        Прогресс изучения материала
                    </p>
                    <ProgressBar
                        percent={item?.session?.percent_progress || 0}
                        background="#566DA3"
                        color="#566DA3"
                        height="3px"
                        fontSize="14px"
                        lineHeight="16.8px"
                        top="-20px"
                        right=""
                    />
                </div>
                <div className="relative flex w-full flex-col gap-[26px]">
                    <p className="text-[12px] font-medium leading-[14.4px] text-[#264354B2]">
                        Достижения за прохождение
                    </p>
                    <div className="flex justify-between">
                        <p className="w-[50%] text-[14px] font-medium leading-[16.8px] text-[#73BDE9]">
                            Достижения за прохождение этого материала не
                            добавлено
                        </p>
                        <button
                            className="flex h-[35px] items-center rounded-full bg-[#73C3F3CC] px-[18px] py-[5px]"
                            onClick={handleClickLink}
                        >
                            <p className="text-[14px] font-medium text-[#FFFFFF] ">
                                {item?.session?.completion_status ===
                                "not_attempted"
                                    ? `Начать`
                                    : "Продолжить"}{" "}
                                &#8594;
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
