import { Outlet } from "react-router-dom";
import { Header } from "../components/Header/Header";
import { TabBar } from "components/TabBar/TabBar";

function UserLayout() {
    return (
        <div className="flex h-screen flex-col overflow-hidden text-textblack">
            <Header /*isAdmin={false}*/ />
            <Outlet />
            <TabBar />
        </div>
    );
}

export default UserLayout;
