import { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import { UserInfo } from "shared/types";
interface Props {
    data: UserInfo;
}
export const ProgressActiveMat: React.FC<Props> = ({ data }) => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
    }, [width]);

    const option = {
        color: [
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                    offset: 0,
                    color: "#F3A773",
                },
                {
                    offset: 1,
                    color: "#F3A773",
                },
            ]),
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                    offset: 0,
                    color: "#566DA3",
                },
                {
                    offset: 1,
                    color: "#566DA3",
                },
            ]),
        ],
        series: [
            {
                name: "Item",
                type: "pie",
                radius: ["80%", "100%"],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderWidth: 2,
                    borderColor: "#fff",
                },
                label: {
                    show: false,
                    position: "center",
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: width > 1010 ? 24 : 16,
                        fontWeight: "bold",
                        color: function (params) {
                            return option.color[params.dataIndex];
                        },
                    },
                },
                hoverAnimation: false,
                data: [
                    {
                        value:
                            (data.completed_actives_count /
                                data.actives_count) *
                                100 || 0,
                        name: `${data.completed_actives_count}/${data.actives_count}`,
                    },
                    {
                        value:
                            (data.completed_content_count /
                                data.content_count) *
                                100 || 0,
                        name: `${data.completed_content_count}/${data.content_count}`,
                    },
                ],
            },
        ],
    };

    return (
        <ReactECharts
            style={{ height: "100%", width: "100%" }}
            option={option}
        />
    );
};
