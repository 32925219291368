import { OrdersPieChartMaterial } from "components/OrdersPieChart/OrdersPieChartMaterial";
import { useNavigate } from "react-router-dom";
import { ReactComponent as TypeWeb } from "shared/ui/icon/TypeWebGoal.svg";
import { ReactComponent as TypePdf } from "shared/ui/icon/TypePdfGoal.svg";
import { ReactComponent as TypeScorm } from "shared/ui/icon/TypeScormGoal.svg";
import { ReactComponent as TypeVideo } from "shared/ui/icon/TypeVideoGoal.svg";
import { ReactComponent as TypeArcticle } from "shared/ui/icon/TypeArcticleGoal.svg";
import { ReactComponent as TypeConfGoal } from "shared/ui/icon/TypeConfGoal.svg";
import { truncateText } from "utils/truncateText";

export const CardLastLearning = ({ item }) => {
    const navigate = useNavigate();

    const handleClickLink = (id) => {
        navigate(`/session/${id}`);
    };

    return (
        <div
            className="flex w-full max-w-[215px] flex-col gap-[20px]"
            onClick={() => handleClickLink(item?.id)}
        >
            <div className="relative">
                {item?.content_type?.model === "articlecontent" ? (
                    <TypeArcticle className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform" />
                ) : item?.content_type?.model === "videocontent" ? (
                    <TypeVideo className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform" />
                ) : item?.content_type?.model === "scormpackage" ? (
                    <TypeScorm className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform" />
                ) : item?.content_type?.model === "pdfcontent" ? (
                    <TypePdf className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform" />
                ) : item?.content_type?.model === "webresourcecontent" ? (
                    <TypeWeb className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform" />
                ) : item?.content_type?.model === "meeting" ? (
                    <TypeConfGoal className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform" />
                ) : (
                    ""
                )}
                <OrdersPieChartMaterial data={item} width={80} />
            </div>
            <p className="text-center text-[24px] font-bold leading-[28.8px] text-[#566DA3]">
                {item?.session?.percent_progress}%
            </p>
            <div className="flex flex-col gap-[5px] text-start">
                <p className="text-[14px] font-normal leading-[16.8px] text-[#264354]">
                    Наименование цели
                </p>
                <p className="text-[16px] font-medium leading-[19.2px] text-[#264354]">
                    {truncateText(item?.name, 0.7)}
                </p>
            </div>
        </div>
    );
};
