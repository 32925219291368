import { ReactComponent as Material } from "shared/image/icon/MaterialIcons.svg";
import { ReactComponent as CheckGreen } from "shared/image/icon/CheckMarkGreen.svg";
import { truncateText } from "../../utils/truncateText";
import { formatTime } from "../../utils/formatTime";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { OrdersPieChartMaterial } from "components/OrdersPieChart/OrdersPieChartMaterial";

export const CardMaterialTimeline = ({ item, skillName, allMaterials }) => {
    const navigate = useNavigate();

    const handleClickLink = () => {
        navigate(`/session/${item.id}`);
    };

    return (
        <li className="flex flex-col gap-[30px]" key={item.id}>
            <div
                className={`mx-[10px] flex h-[227px] w-[455px] flex-col justify-between rounded-md shadow-[0px_7px_22px_0px_#2643541A]`}
            >
                <div className="flex flex-col gap-[20px] px-[10px] pb-[2px] pt-[20px]">
                    {!allMaterials && (
                        <div className="flex items-start">
                            <p className="rounded-full bg-[#369F48] px-[20px] py-[5px] text-center text-[10px] font-medium leading-[12px] text-white">
                                {!allMaterials && skillName}
                            </p>
                        </div>
                    )}
                    <div className="flex gap-[28px]">
                        <div className="flex w-full max-w-[203px] items-center justify-between rounded-lg bg-[#73C3F333] p-[10px] text-center">
                            <div className="relative">
                                <OrdersPieChartMaterial
                                    data={item}
                                    width={90}
                                />
                                {item?.session?.percent_progress === 100 ? (
                                    <div className="absolute left-1/2 top-1/2 h-[90%] w-[90%] -translate-x-1/2 -translate-y-1/2 rounded-[50%] bg-[#3CAA3C33]">
                                        <CheckGreen className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
                                    </div>
                                ) : (
                                    <div className="absolute left-1/2 top-1/2 h-[90%] w-[90%] -translate-x-1/2 -translate-y-1/2 rounded-[50%] bg-white">
                                        <Material className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
                                    </div>
                                )}
                            </div>
                            <div className="flex w-full max-w-[68px] flex-col">
                                <div className="mt-[10px] text-[16px] font-medium leading-[19.2px] text-[#264354CC]">
                                    {item?.session?.percent_progress}%
                                </div>
                                <div className="mt-[4px] text-[10px] font-normal leading-[12px] text-[#F37D73]">
                                    Прогресс
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full max-w-[210px] h-full gap-[10px]">
                            
                                <p
                                    className="flex text-[14px] font-medium leading-[16.8px] text-[#F37D73]"
                                    data-tooltip-content={`${item?.content_type?.custom_name}`}
                                    data-tooltip-id={`${item?.content_type?.custom_name}`}
                                >
                                    
                                    {item?.content_type?.custom_name === "Интерактивный Курс (Scorm)" ? (
                                        <>
                                            SCORM
                                        </>
                                    ):(
                                        <>
                                            {truncateText(
                                                item?.content_type?.custom_name,
                                                1
                                            )}
                                        </>
                                    )}
                                </p>
                                <Tooltip
                                    id={`${item?.content_type?.custom_name}`}
                                />
                                <p className="text-[16px] font-medium leading-[19.2px] text-[#264354] break-words">
                                    {truncateText(item?.name, 5)}
                                </p>
                            
                        </div>
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={handleClickLink}
                        className="flex min-h-[37px] w-full max-w-[215px] items-center justify-center rounded-br-md rounded-tl-md bg-[#73C3F3] text-[14px] text-white"
                    >
                        {item?.session?.percent_progress === 100
                            ? "Повторить"
                            : item?.session?.completion_status ===
                              "not_attempted"
                            ? "Начать"
                            : "Продолжить"}{" "}
                        &rarr;
                    </button>
                </div>
            </div>
            <div className="flex min-w-[215px] flex-col gap-[10px]">
                <p className="text-center text-[12px] font-medium leading-[14.4px] text-[#F37D73]">
                    {formatTime(item.content_duration)}
                </p>
                <div className="relative flex justify-center border-[4px] border-t border-[#BDD7E7]">
                    <p className="absolute left-1/2 top-1/2 z-[1] -translate-x-1/2 -translate-y-1/2 text-[18px] text-[#BDD7E7]">
                        🌢
                    </p>
                </div>
            </div>
        </li>
    );
};
