export const getWordEnding = (number, endings) => {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (
        lastTwoDigits === 11 ||
        lastTwoDigits === 12 ||
        lastTwoDigits === 13 ||
        lastTwoDigits === 14
    ) {
        return endings[2];
    } else if (lastDigit === 1) {
        return endings[0];
    } else if (lastDigit === 2 || lastDigit === 3 || lastDigit === 4) {
        return endings[1];
    } else {
        return endings[2];
    }
};
