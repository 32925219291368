import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";

export const OrdersPieChartMaterial = ({ data, width }) => {
    const percentage = data.session?.percent_progress || 0;

    const option = {
        color: [
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                    offset: 0,
                    color: "#566DA3",
                },
                {
                    offset: 1,
                    color: "#566DA3",
                },
            ]),
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                    offset: 0,
                    color: "#566DA34D",
                },
                {
                    offset: 1,
                    color: "#566DA34D",
                },
            ]),
        ],
        series: [
            {
                name: "Item",
                type: "pie",
                radius: [`${width}%`, "100%"],
                avoidLabelOverlap: false,
                label: {
                    position: "center",
                },
                emphasis: {
                    scale: false,
                },
                data: [
                    {
                        value: percentage,
                        name: "",
                        itemStyle: {
                            color: percentage === 100 ? "#3CAA3C" : undefined,
                        },
                    },
                    { value: 100 - percentage, name: "" },
                ],
            },
        ],
    };

    return (
        <ReactECharts
            style={{ height: "100%", width: "100%" }}
            option={option}
        />
    );
};
