import React from "react";

type Params = {
    width?: number;
    progress?: number;
    className?: string;
};

function ProgressCircle({
    width = 5,
    progress = 0.25,
    className = "",
}: Params) {
    const radius = 100;
    return (
        <svg
            className={className}
            viewBox={`0 0 ${radius * 2.2} ${radius * 2.2}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                className="text-slightblue"
                strokeWidth={width}
                stroke="currentColor"
                fill="transparent"
                r={radius}
                cx="50%"
                cy="50%"
            />
            <circle
                className="text-blue"
                strokeWidth={width}
                strokeDasharray={2 * Math.PI * radius}
                strokeDashoffset={
                    2 * Math.PI * radius - 2 * Math.PI * radius * progress
                }
                strokeLinecap="round"
                stroke="currentColor"
                fill="transparent"
                transform={`rotate(-90 ${radius * 1.1} ${radius * 1.1})`}
                r={radius}
                cx="50%"
                cy="50%"
            />
        </svg>
    );
}

export default ProgressCircle;
