import { ButtonsArrow } from "components/ButtonsArrow/ButtonsArrow";
import { Calendar } from "components/Calendar/Calendar";
import { CardGoalMain } from "components/CardGoalMain/CardGoalMain";
import { CardLastLearning } from "components/CardLastLearning/CardLastLearning";
import { Loader } from "components/Loader/Loader";
import { NoMaterialLearning } from "components/NoMaterialLearning/NoMaterialLearning";
import { ProgressActiveMat } from "components/OrdersPieChart/ProgressActiveMat";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useGoalsList from "shared/api/useGoalsList";
import { useIncomplete } from "shared/api/useIncomplete";
import useUser from "shared/api/useUser";
import useUserInfo from "shared/api/useUserInfo";
import DemoButton from "shared/ui/buttons/DemoButton";
import "./index.css";

function MainPage() {
    const { user, isLoading: isUserLoading, isError: isUserError } = useUser();
    const { userData, isLoading, isError } = useUserInfo();
    const activeAchievementsList = useIncomplete();
    const { goalsList } = useGoalsList();
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
    }, [width]);

    // TODO добавить крутилку
    if (isUserLoading || isLoading || isError || isUserError)
        return <Loader text="Загрузка..." />;

    return (
        <section className="relative flex h-full w-full flex-col items-center overflow-y-scroll px-[16px] pb-[26px] max-[834px]:pb-[76px]">
            <div className="mt-[30px] flex w-full max-w-[1415px]">
                <div className="mr-[10px] flex w-full max-w-[1415px] flex-col gap-[65px] max-[834px]:gap-[60px]">
                    <div className="flex flex-col gap-[19px]">
                        <p className="text-[14px] font-normal leading-[16.8px] text-[#2643544D]">
                            Главная
                        </p>
                        <h2 className="text-[20px] font-bold leading-[24px] text-[#264354] max-[834px]:text-[16px] max-[834px]:leading-[19.2px]">
                            Здравствуй, {user?.first_name}!
                        </h2>
                        <p className="text-[16px] font-normal leading-[19.2px] text-[#264354] max-[1010px]:text-[14px] max-[1010px]:leading-[16.8px]">
                            Отличный день, чтобы продолжить обучение!
                        </p>
                    </div>
                    <div className="flex h-[216px] w-full max-w-[535px] items-center gap-[25px] max-[1010px]:h-[119px]">
                        <div className="h-full min-w-[216px] max-[1010px]:min-w-[119px]">
                            <ProgressActiveMat data={userData} />
                        </div>
                        <div className="flex h-full max-h-[98px] w-full max-w-[60%] flex-col justify-between">
                            <div className="flex w-full items-end gap-[13px]">
                                <p className="text-[24px] font-medium leading-[28.8px] text-[#F3A773]">
                                    {Math.round(
                                        (userData.completed_actives_count /
                                            userData.actives_count) *
                                            100
                                    ) || 0}
                                    %
                                </p>
                                <p className="text-[14px] font-normal leading-[16.8px] text-[#264354CC] max-[1010px]:text-[12px] max-[1010px]:leading-[14.4px]">
                                    Достигнутых целей
                                </p>
                            </div>
                            <div className="flex w-full items-end gap-[13px]">
                                <p className="text-[24px] font-medium leading-[28.8px] text-[#566DA3]">
                                    {Math.round(
                                        (userData.completed_content_count /
                                            userData.content_count) *
                                            100
                                    ) || 0}
                                    %
                                </p>
                                <p className="text-[14px] font-normal leading-[16.8px] text-[#264354CC] max-[1010px]:text-[12px] max-[1010px]:leading-[14.4px]">
                                    Освоенных учебных материалов
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {width > 834 && <Calendar />}
            </div>

            <div className="mt-[60px] flex w-full max-w-[1415px] flex-col gap-[40px] max-[1010px]:mt-[50px] max-[834px]:mt-[40px] max-[834px]:gap-[20px]">
                <div className="flex w-full items-center justify-between">
                    <p className="text-[20px] font-bold leading-[24px] text-[#264354] max-[834px]:text-[16px] max-[834px]:leading-[19.2px]">
                        Ваши цели
                    </p>
                    <p
                        className="hidden cursor-pointer text-[12px] font-medium leading-[14.4px] text-[#264354B2]"
                        onClick={() => navigate("/goals")}
                        // TODO удалить окончательно
                    >
                        Смотреть все цели &#8594;
                    </p>
                </div>
                <div className="flex flex-col gap-[20px]">
                    {goalsList?.results.length > 0 ? (
                        <>
                            <div
                                className="container-scroll flex w-full gap-[25px] px-[5px] py-[10px]"
                                style={{ overflowX: "hidden" }}
                            >
                                {goalsList?.results.map((goal) => {
                                    return (
                                        <CardGoalMain
                                            key={goal.id}
                                            active={goal}
                                        />
                                    );
                                })}
                            </div>
                            <div className="relative mb-[60px] flex w-full items-center justify-center max-[834px]:mb-[40px]">
                                <ButtonsArrow />
                                <p
                                    className="absolute right-0 top-0 hidden cursor-pointer text-[14px] font-medium leading-[16.8px] text-[#264354B2]"
                                    onClick={() => navigate("/goals")}
                                    // TODO: удалить окончательно
                                >
                                    Смотреть все цели &#8594;
                                </p>
                            </div>
                        </>
                    ) : (
                        <div className="text-center">Данных нет</div>
                    )}
                </div>
            </div>

            <div className="flex w-full max-w-[1415px] items-center">
                <div className="flex w-full flex-col gap-[60px] max-[1010px]:gap-[40px] max-[834px]:gap-[20px]">
                    <div className="flex w-full items-center justify-between">
                        <p className="text-[20px] font-bold leading-[24px] text-[#264354] max-[834px]:text-[16px] max-[834px]:leading-[19.2px]">
                            Продолжить обучение
                        </p>
                        <p
                            className="hidden cursor-pointer text-[12px] font-medium leading-[14.4px] text-[#264354B2] max-[834px]:flex"
                            onClick={() => navigate("/materials")}
                        >
                            Смотреть все материалы &#8594;
                        </p>
                    </div>
                    {activeAchievementsList?.data?.results.length >= 0 &&
                    activeAchievementsList?.data?.results.some(
                        (item) =>
                            item.session.completion_status === "incomplete"
                    ) ? (
                        <>
                            <div className="flex w-full gap-[25px]">
                                {activeAchievementsList?.data?.results.map(
                                    (item, index) =>
                                        item.session.completion_status ===
                                            "incomplete" && (
                                            <DemoButton key={index}>
                                                <CardLastLearning item={item} />
                                            </DemoButton>
                                        )
                                )}
                            </div>
                            <DemoButton>
                                <p
                                    className="text-right text-[14px] font-medium leading-[16.8px] text-[#264354B2] max-[834px]:hidden"
                                    onClick={() => navigate("/materials")}
                                >
                                    Смотреть все материалы &#8594;
                                </p>
                            </DemoButton>
                        </>
                    ) : (
                        <NoMaterialLearning />
                    )}
                </div>
            </div>
        </section>
    );
}

export default MainPage;
