import { useState } from "react";

export const Calendar = () => {
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const months = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
    ];

    const daysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const firstDayOfMonth = (month, year) => {
        return new Date(year, month, 1).getDay();
    };

    const handleMonthChange = (direction) => {
        if (direction === "prev") {
            if (currentMonth === 0) {
                setCurrentMonth(11);
                setCurrentYear(currentYear - 1);
            } else {
                setCurrentMonth(currentMonth - 1);
            }
        } else if (direction === "next") {
            if (currentMonth === 11) {
                setCurrentMonth(0);
                setCurrentYear(currentYear + 1);
            } else {
                setCurrentMonth(currentMonth + 1);
            }
        }
    };

    return (
        <div className="mt-[36px] flex w-full max-w-[455px] flex-col gap-[40px] max-[1010px]:max-w-[334px]">
            <div className="flex items-center justify-between px-4">
                <button
                    aria-label="calendar backward"
                    className="text-[20px] text-gray-800 focus:text-gray-400 hover:text-gray-400"
                    onClick={() => handleMonthChange("prev")}
                >
                    ←
                </button>
                <span
                    tabIndex={0}
                    className="cursor-pointer text-[20px] font-bold leading-[24px] text-[#264354] opacity-[60%] max-[1010px]:text-[14px] max-[1010px]:leading-[16.8px]"
                    onClick={() => handleMonthChange("prev")}
                >
                    {months[currentMonth - 1]}
                </span>
                <span
                    tabIndex={0}
                    className="text-[32px] font-bold leading-[38.4px] text-[#264354] max-[1010px]:text-[20px] max-[1010px]:leading-[24px]"
                >
                    {months[currentMonth]}
                </span>
                <span
                    tabIndex={0}
                    className="cursor-pointer text-[20px] font-bold leading-[24px] text-[#264354] opacity-[60%] max-[1010px]:text-[14px] max-[1010px]:leading-[16.8px]"
                    onClick={() => handleMonthChange("next")}
                >
                    {months[currentMonth + 1]}
                </span>
                <button
                    aria-label="calendar forward"
                    className="ml-3 text-[20px] text-gray-800 focus:text-gray-400 hover:text-gray-400"
                    onClick={() => handleMonthChange("next")}
                >
                    →
                </button>
            </div>
            <div className="shadow-custom h-full min-h-[372px] w-full rounded-[14px] p-[10px] max-[1010px]:min-h-[294px]">
                <div className="flex items-center justify-between overflow-x-auto p-[5px]">
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th>
                                    <div className="flex w-full max-w-[54px] justify-center">
                                        <p className="text-center text-[17px] font-medium leading-[19.36px] text-[#F37D73] max-[1010px]:text-[12.44px] max-[1010px]:leading-[14.21px]">
                                            Пн
                                        </p>
                                    </div>
                                </th>
                                <th>
                                    <div className="flex w-full max-w-[54px] justify-center">
                                        <p className="text-center text-[17px] font-medium leading-[19.36px] text-[#F37D73] max-[1010px]:text-[12.44px] max-[1010px]:leading-[14.21px]">
                                            Вт
                                        </p>
                                    </div>
                                </th>
                                <th>
                                    <div className="flex w-full max-w-[54px] justify-center">
                                        <p className="text-center text-[17px] font-medium leading-[19.36px] text-[#F37D73] max-[1010px]:text-[12.44px] max-[1010px]:leading-[14.21px]">
                                            Ср
                                        </p>
                                    </div>
                                </th>
                                <th>
                                    <div className="flex w-full max-w-[54px] justify-center">
                                        <p className="max-[1010px]]:text-[12.44px] text-center text-[17px] font-medium leading-[19.36px] text-[#F37D73] max-[1010px]:leading-[14.21px]">
                                            Чт
                                        </p>
                                    </div>
                                </th>
                                <th>
                                    <div className="flex w-full max-w-[54px] justify-center">
                                        <p className="text-center text-[17px] font-medium leading-[19.36px] text-[#F37D73] max-[1010px]:text-[12.44px] max-[1010px]:leading-[14.21px]">
                                            Пт
                                        </p>
                                    </div>
                                </th>
                                <th>
                                    <div className="flex w-full max-w-[54px] justify-center">
                                        <p className="text-center text-[17px] font-medium leading-[19.36px] text-[#F37D73] max-[1010px]:text-[12.44px] max-[1010px]:leading-[14.21px]">
                                            Сб
                                        </p>
                                    </div>
                                </th>
                                <th>
                                    <div className="flex w-full max-w-[54px] justify-center">
                                        <p className="text-center text-[17px] font-medium leading-[19.36px] text-[#F37D73] max-[1010px]:text-[12.44px] max-[1010px]:leading-[14.21px]">
                                            Вс
                                        </p>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array(6)
                                .fill(null)
                                .map((_, week) => (
                                    <tr>
                                        {Array(7)
                                            .fill(null)
                                            .map((_, day) => {
                                                const date =
                                                    day -
                                                    firstDayOfMonth(
                                                        currentMonth,
                                                        currentYear
                                                    ) +
                                                    1 +
                                                    week * 7;
                                                const isCurrentMonth =
                                                    date > 0 &&
                                                    date <=
                                                        daysInMonth(
                                                            currentMonth,
                                                            currentYear
                                                        );
                                                const isToday =
                                                    isCurrentMonth &&
                                                    date ===
                                                        new Date().getDate() &&
                                                    currentMonth ===
                                                        new Date().getMonth() &&
                                                    currentYear ===
                                                        new Date().getFullYear();
                                                const isPastDate =
                                                    currentYear <
                                                        new Date().getFullYear() ||
                                                    (currentYear ===
                                                        new Date().getFullYear() &&
                                                        currentMonth <
                                                            new Date().getMonth()) ||
                                                    (currentYear ===
                                                        new Date().getFullYear() &&
                                                        currentMonth ===
                                                            new Date().getMonth() &&
                                                        date <
                                                            new Date().getDate());
                                                return (
                                                    <td
                                                        key={day}
                                                        className="cursor-pointer"
                                                    >
                                                        {isCurrentMonth ? (
                                                            <button
                                                                role="link"
                                                                tabIndex={0}
                                                                className={`max-[1010px]:h-[35px] max-[1010px]:w-[35px] flex h-[48px] w-[48px] items-center justify-center rounded-full focus:bg-[#73C3F3] focus:text-white focus:outline-none
                                                                ${
                                                                    isToday
                                                                        ? "ring-2 ring-[#F37D73] ring-offset-1"
                                                                        : ""
                                                                } ${
                                                                    isPastDate
                                                                        ? "text-[#A6A6A6]"
                                                                        : "text-[#264354]"
                                                                }
                                                                `}
                                                            >
                                                                <p
                                                                    className={`max-[1010px]:leading-[17.77px] text-[17px] font-normal leading-[24px] max-[1010px]:text-[12.44px]`}
                                                                >
                                                                    {date}
                                                                </p>
                                                            </button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
