import { truncateText } from "utils/truncateText";

export const CardThreatTimeline = ({
    item,
    setSkillId,
    setSkillName,
    skillId,
    setAllMaterials,
    allMaterials,
    setOpenList,
    setSelectedThreat,
}) => {
    return (
        <div
            key={item.id}
            className={`flex w-full h-[80px] border-b-[1px] border-b-[#264354CC] ${
                skillId === item.id && !allMaterials
                    ? "bg-[#369F481A]"
                    : "bg-white"
            } cursor-pointer items-center justify-between bg-[#369F481A] p-[30px] hover:shadow-[0_0_25px_0_rgba(38,67,84,0.1)]`}
            onClick={() => {
                setSkillId(item.id);
                setSkillName(item.threat.name);
                setAllMaterials(false);
                setOpenList(false);
                setSelectedThreat(item);
            }}
        >
            <div className="flex flex-col gap-y-[5px]">
                <p className="font-medium text-[14px] leading-[16.8px] text-[#2643544D]">
                    Навык
                </p>
                <p className="text-[20px] font-medium leading-[24px] text-[#264354CC]">
                    {truncateText(item?.threat?.name, 1.5)}
                </p>
            </div>
            <p className="min-w-[50px] text-center  rounded-full bg-[#566DA3] py-[5px] text-[14px] font-medium leading-[16.8px] text-white">
                {item?.content_count > 100 ? "100+" : item?.content_count}
            </p>
        </div>
    );
};
