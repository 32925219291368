import React from "react";
import ReactDOM from "react-dom/client";
import Toaster from "shared/ui/Toaster";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

import "./index.css";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
            <Toaster />
        </BrowserRouter>
    </React.StrictMode>
);
