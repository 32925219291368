import { getWordEnding } from "./getWordEnding";

export const formatTime = (time) => {
    if (time) {
        const [hours, minutes, sec] = time.split(":");
        let timeString = "";

        if (parseInt(hours) > 0) {
            const hoursEnding = getWordEnding(hours, ["час", "часа", "часов"]);
            timeString += `${hours} ${hoursEnding} `;
        }

        if (parseInt(minutes) > 0) {
            const minutesEnding = getWordEnding(minutes, [
                "минута",
                "минуты",
                "минут",
            ]);
            timeString += `${minutes} ${minutesEnding} `;
        }

        if (parseInt(sec) > 0) {
            const secEnding = getWordEnding(sec, [
                "секунда",
                "секунды",
                "секунд",
            ]);
            timeString += `${sec} ${secEnding}`;
        }

        return timeString.trim() || "Срок отсутствует";
    } else {
        return "Срок отсутствует";
    }
};
