import ElementNew from "../Card/Card";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LineRed from "shared/ui/icon/LineRed.svg";
import LineBlue from "shared/ui/icon/LineBlue.svg";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { ButtonStart } from "../ButtonStart/ButtonStart";
import { ReactComponent as Statistic } from "shared/ui/icon/Statistic.svg";
import Img from "../../shared/ui/icon/target.svg";
import { TrajectoryActiveType } from "shared/types";
import { truncateText } from "../../utils/truncateText";
import useMaterialsGoal from "shared/api/useMaterialsGoal";

interface Props {
    active: TrajectoryActiveType;
}

export const GoalItem: React.FC<Props> = ({ active }) => {
    const navigate = useNavigate();
    const [hovered, sethovered] = useState(false);
    const { data: activeMaterials } = useMaterialsGoal(active.id);

    const handleClickLink = async () => {
        navigate(`/timeline/${active.id}`, { state: { active } });
    };

    if (!activeMaterials?.results?.length) {
        return null;
    }

    return (
        <div
            onMouseEnter={() => sethovered(true)}
            onMouseLeave={() => sethovered(false)}
            onClick={handleClickLink}
        >
            <ElementNew className="">
                <div className="flex h-full w-full min-w-[200px] max-w-[334px] items-center rounded-l-md px-[14px] py-[15px]">
                    <div className="flex flex-col justify-center">
                        <p className="flex h-[37px] items-end text-[14px] font-medium text-[#F37D73] max-[1010px]:text-[10.78px] max-[1010px]:leading-[12.94px]">
                            Цель
                        </p>
                        <p className="flex max-h-[48px] min-h-[36px] items-end text-[16px] font-semibold text-textblack max-[1010px]:text-[12.32px] max-[1010px]:leading-[14.79px]">
                            {truncateText(active.active.name, 1.4)}
                        </p>
                    </div>
                </div>
                {hovered === false ? (
                    <img src={LineBlue} alt="lineBlue" />
                ) : (
                    <img src={LineRed} alt="lineRed" />
                )}
                <div className="relative flex w-full max-w-[715px] flex-col px-[19px] py-[15.5px] opacity-20 group-hover:opacity-100">
                    <div className="mb-[24px] flex min-w-[200px] justify-start text-end max-[1010px]:mb-[12px]">
                        <Statistic className="w-[22px] max-[1010px]:w-[16px]" />
                        <p className="ml-[5px] mt-[5px] text-sm font-medium leading-[16.8px] text-[#264354B2] max-[1010px]:text-[10.78px] max-[1010px]:leading-[12.94px]">
                            Твой прогресс по цели
                        </p>
                    </div>
                    <div className="flex">
                        <div className="flex w-full min-w-[100px] max-w-[200px] flex-col">
                            <p className="mb-[38px] text-[12px] font-medium leading-[14.4px] text-[#264354B2] max-[1010px]:mb-[20px] max-[1010px]:text-[8.92px] max-[1010px]:leading-[10.7px]">
                                Прогресс достижения цели
                            </p>
                            <ProgressBar
                                percent={
                                    Math.round(
                                        (Number(
                                            active.completed_threats_count
                                        ) *
                                            100) /
                                            Number(active.threats_count)
                                    ) || 0
                                }
                                background="#F37D73"
                                color="#F37D73"
                                height="3px"
                                fontSize=""
                                lineHeight=""
                                top=""
                                right=""
                            />
                        </div>
                    </div>
                </div>
                <>
                    {hovered === false ? (
                        <img src={LineBlue} alt="lineBlue" />
                    ) : (
                        <img src={LineRed} alt="lineRed" />
                    )}
                </>
                <div className="relative flex w-full min-w-[250px] max-w-[348px] flex-col items-start justify-between overflow-hidden rounded-r-xl px-[19px] py-[15.5px] opacity-20 group-hover:opacity-100">
                    <p className="text-[14px] font-medium leading-[16.8px] text-[#264354B2] max-[1010px]:text-[10.78px] max-[1010px]:leading-[12.94px]">
                        Освоенных навыков в рамках цели
                    </p>
                    <p className="text-[48px] font-medium leading-[57.6px] text-[#F37D7366] max-[1010px]:text-[23.79px] max-[1010px]:leading-[28.54px]">
                        {active?.completed_threats_count}
                    </p>
                    <ButtonStart
                        text={`${
                            Math.round(
                                (Number(active?.completed_threats_count) *
                                    100) /
                                    Number(active?.threats_count)
                            ) === 0
                                ? "Начать обучение"
                                : "Продолжить обучение"
                        }`}
                        handleClickLink={handleClickLink}
                    />
                    <img
                        className="absolute bottom-[-25px] right-[-15px] z-0 h-[136.66px] w-[112.06px] rotate-[-15deg] transform opacity-[0.7]"
                        src={Img}
                        alt="Картинка"
                    />
                </div>
            </ElementNew>
        </div>
    );
};
